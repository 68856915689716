import React from "react";
import styled from "styled-components";

const StyledButton = styled.button<{
  blue?: boolean;
}>`
  background: ${({ blue }) => (blue ? "var(--dark-blue-2)" : "var(--orange)")};
  border: 0;
  border-radius: 2px;
  color: white;
  font-size: 1.6rem;
  line-height: 1.5em;
  width: 200px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:disabled {
    cursor: auto;
    background: #dddfdf; // comes from KLM.nl
  }

  &:hover:not(:disabled) {
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
      0 3px 14px 2px rgb(0 0 0 / 12%);
  }
`;

type ButtonProps = {
  blue?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledButton {...props}>
      <>{children}</>
    </StyledButton>
  );
};

export default Button;
